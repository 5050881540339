.burger-menu {
    position: relative;
  }
  
  .menu-icon {
    top: 20px;
    right: 20px;
    width: 30px;
    height: 22px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    z-index: 2;
  }
  
  .menu-icon .icon-line {
    width: 100%;
    height: 3px;
    background-color: #333;
    transition: all 0.3s ease-in-out;
  }
  
  .menu {
    position: fixed;
    visibility: hidden;
  }
  
  .menu.open {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 10px;
    margin-left: 10px;
    visibility: visible;
  }
  

  